<template>
  <div class="player-popup">
    <div
      class="player-info"
      :class="localDisplayLang === 'ara' ? 'rtl_align' : ''"
      :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
    >
      <div @click="closePlayer">
        <img
          class="rtl_player_close_icon"
          src="@/assets/icons/Combined_Shape.svg"
          alt
          v-if="localDisplayLang === 'ara'"
        />
        <img class="player-close-icon" src="@/assets/icons/less-than-filled-50.png" alt v-else />
      </div>
      <div>
        <p class="content-title">{{ content != undefined ? content.title : "" }}</p>
      </div>
    </div>
    <div class="vlive-player-container" id="vlive-player-container">
      <div id="vlive-player"></div>
    </div>
    <transition>
      <message
        v-if="isMessagePopup"
        :messageData="errorPopupData"
        :closePopup="() => toggleMessagePopup(false)"
      ></message>
    </transition>
  </div>
</template>

<script>
import { vLivePlayer, vLivePlaylistItem } from "/src/vue-player/vLiveWebPlayer.js";
import Utility from "@/mixins/Utility.js";
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters } from "vuex";
import FirebaseActions from "@/mixins/FirebaseActions";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";
import branchIO from "@/mixins/branchEvents.js";
import common from "@/mixins/common.js";
import firebase from "firebase/app";
import "firebase/database";

import { getAudioLanguageFromPlayer, getSubtitleLanguageFromPlayer } from "@/utilities.js";

export default {
  props: {
    closePlayerEvent: {
      type: Function,
    },
    playerInstance: {
      type: Object,
    },
    content: {
      type: Object,
    },
    seriesData: {
      type: Object,
    },
  },
  data() {
    return {
      player: undefined,
      streamUrl: null,
      isMessagePopup: false,
      errorPopupData: null,
      timer: 0,
      progress: null,
      networkType: null,
      filteredAvailabilities: [],
      contentpricemodel: null,
      pricemodel: [],
      availabilities: [],
      subscriptions: [],
      playlistArray: [],
      localDisplayLang: null,
      contentSettingInfo: {
        audio: null,
        subtitle: null,
        quality: null,
        watchedTime: 0,
      },
      counterTimer: 0,

      startPositionForStreamApi: 0,
      throttledStreamAPIFunc: null,

      contentStartPosition : null
    };
  },

  computed: {
    ...mapGetters(["subscriberid", "appConfig", "availabilityList", "subscriptionList", "getCountry", "getAllLanguageFullForm"]),
  },

  watch: {
    playerInstance(val) {
      if (val) {
        // Register progress event in player to update firebase.
        this.playerInstance.registerEvent("contentprogress", this.videoContentProgressCB);

        // When content is ended.
        this.playerInstance.registerEvent("contentended", this.videoContentEndedCB);

        this.playerInstance.registerEvent("content-change-subtitle", this.playerChangeSubtitleCB);
        this.playerInstance.registerEvent("content-change-audio", this.playerChangeAudioCB);
        this.playerInstance.registerEvent("content-change-quality", this.playerChangeQualityCB);
        this.playerInstance.registerEvent("currentcontentended", this.videoPlaybackEnd);
        this.playerInstance.registerEvent("pausecontent", this.videoPlaybackPaused);
        this.playerInstance.registerEvent("playcontent", this.videoPlaybackResume);
        this.playerInstance.registerEvent("itemClickedPlayList-register", this.videoPlayListItemClicked);
        this.playerInstance.registerEvent("itemClickedPlayList", this.videoPlayListItemClicked);
        this.playerInstance.registerEvent("unloaded", this.videoPlayListPlayerUnloaded);
      }
    },

    content(val) {
      if (val) {
        this.startPositionForStreamApi = 0;
        if (val.watchedduration) {
          this.startPositionForStreamApi = val.watchedduration;
        }
      }
    },
  },

  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    eventBus.$on("check-local-storage", (localeLang) => {
      this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    });

    window.addEventListener("playerPlayListClicked", () => {
      this.videoPlayListItemClicked();
    });
  },

  mounted() {
    // Create player instance initially.
    eventBus.$emit("vlive-player-ready", true);

    this.throttledStreamAPIFunc = this.throttleFunction(this.setContentStreamAPIFunc, 10000);

    console.log("THE Player Sttart Time --- > ", this.content);
      window.addEventListener("eventPlayerSafariClose", () => {
        console.error("ok-test-close")
        this.closePlayer();
      });
  },

  methods: {
    ...mapActions(["relatedContents", "unsetCurrentStreamApi", "authorizeCurrentStreamApi", "setCurrentStreamApi"]),

    playerChangeSubtitleCB(cb) {
      this.contentSettingInfo.subtitle = cb.subtitle.cell;
      console.log("this.contentSettingInfo", this.contentSettingInfo);
    },

    videoPlaybackPaused(cb) {
      console.log("THE PLAYBACK HAS BEEN PASUED --- ", cb);

      const unSetPayload = {
        contentId: this.content.objectid,
      };

      if (this.appConfig.featureEnabled.isConcurrencyEnabled) {
        this.unsetCurrentStreamApi(unSetPayload).then((unSetStreamResp) => {
          console.log("THE UNSET PLAYER API IS CALLED --> ", unSetStreamResp);
        });
      }
    },

    videoPlaybackResume(cb) {
      console.log("THE PLAYBACK HAS BEEN RESUMED -- > ", cb);

      const payload = {
        contentId: this.content.objectid,
        params: {
          devicetype: "PC",
        },
      };

      // If concurrency is enabled form config
      if (this.appConfig.featureEnabled.isConcurrencyEnabled) {
        this.setCurrentStreamApi(payload).then((setStreamResponse) => {
          console.log("THIS IS THE SET STREAM API REPEAT RESPONSE -- > ", setStreamResponse);

          if (setStreamResponse.data.success) {
            this.startPositionForStreamApi = this.progress.position;
          } else if (setStreamResponse.data.errorcode && setStreamResponse.data.errorcode === 9902) {
            this.hidePlayer();

            eventBus.$emit("showAuthorizeErrorMessage", {
              state: true,
              messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
            });
          } else if (setStreamResponse.data.errorcode && setStreamResponse.data.errorcode === 6066) {
            // 6066
            setTimeout(() => {
              this.setCurrentStreamApi(payload).then((setStreamResponseRecal) => {
                if (setStreamResponseRecal.data.success) {
                  this.startPositionForStreamApi = this.progress.position;
                } else if (setStreamResponseRecal.data.errorcode && setStreamResponseRecal.data.errorcode === 9902) {
                  this.hidePlayer();

                  eventBus.$emit("showAuthorizeErrorMessage", {
                    state: true,
                    messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
                  });
                }
              });
            }, 5000);
          }
        });
      }
    },

    videoPlayListItemClicked(cb) {
      console.log("THE Playlist Item has been clicked -- > ", cb);

      const unSetPayload = {
        contentId: this.content.objectid,
      };

      if (this.appConfig.featureEnabled.isConcurrencyEnabled) {
        this.unsetCurrentStreamApi(unSetPayload).then((unSetStreamResp) => {
          console.log("THE UNSET PLAYER API IS CALLED --> ", unSetStreamResp);
        });
      }

    },

    videoPlayListPlayerUnloaded(cb) {
      console.log("THE CURRENT PLAYER UNLOADED -- > ", cb);
    },

    playerChangeAudioCB(cb) {
      this.contentSettingInfo.audio = cb.audioTitle.cell;
      console.log("this.contentSettingInfo", this.contentSettingInfo);
    },
    playerChangeQualityCB(cb) {
      this.contentSettingInfo.quality = cb.qualityTitle.cell;
      console.log("this.contentSettingInfo", this.contentSettingInfo);
    },
    videoPlaybackEnd() {
      // Analytics event after the Playback Ends
      this.watchEvent();

      this.contentSettingInfo.watchedTime = 0;
      this.counterTimer = 0;

      this.contentSettingInfo = {
        audio: null,
        subtitle: null,
        quality: null,
        watchedTime: 0,
      };
      this.counterTimer = 0;

      console.log("finshed=======>");
    },

    videoContentEndedCB() {
      console.log("content ended", this.content);

      if (this.content.category !== "MOVIE") {
        this.updateDataOnFirebase();
      }
    },

    closePlayer() {
      // Watch event analytics.
      this.watchEvent();

      this.contentSettingInfo = {
        audio: null,
        subtitle: null,
        quality: null,
        watchedTime: 0,
      };

      this.counterTimer = 0;
      window.dispatchEvent(window.playerResetSpeed)

      console.log("Player Closed",this.$route);

      // if (this.$route.path.includes("movie")) {
      //   const url = new URL(window.location);
      //   let pathname = url.pathname.split("/");
      //   if(pathname && pathname.length && pathname[2] && pathname[3]) {
      //     let finalUrl = `${window.location.origin}/movie/${pathname[2]}/${pathname[3]}/trailer`;
      //     window.location.replace(finalUrl);
      //   }
      // } else if (location.pathname !== "/") {
      //   this.$router.go(-1);
      // }


      // Update to firebase of watched duration.
      this.updateDataOnFirebase();

      // Check for new contents in firebase after content unloaded.
      eventBus.$emit("check-new-fire-data", true);

      const analyticsPayload = {
        content_id: this.content.objectid,
      };

      this.contentWatched(analyticsPayload);

      this.playerInstance.unloadPlayer();

      const unSetPayload = {
        contentId: this.content.objectid,
      };

      if (this.appConfig.featureEnabled.isConcurrencyEnabled) {
        this.unsetCurrentStreamApi(unSetPayload).then((unSetStreamResp) => {
          console.log("THE UNSET PLAYER API IS CALLED --> ", unSetStreamResp);
        });
      }

      eventBus.$emit("player-visibility", false);

      let payload = { content: null, seriesContent: null };

      eventBus.$emit("load-player-content", payload);
      if (this.$route.name !== "Home") {
        this.$router.go(-1);
      }

    },

    updateDataOnFirebase() {
      if (!this.progress) return;
      let watchedDuration = this.progress.position;
      let watchStatus = null;
      let content = this.content;

      if (this.content && this.content.videoType === "Content") {
        let percentOfContentCompeted = Math.floor((watchedDuration / this.content.duration) * 100);

        if (percentOfContentCompeted >= 95) {
          watchStatus = "COMPLETED";
        } else {
          watchStatus = "INPROGRESS";
        }
        this.updateContinueWatch(content, watchStatus, watchedDuration).then((data) => {
          console.log("FIREBASE DATA WRITTEN SUCCESSFULLY", data);
        });
      }
      this.progress = null;
      console.log("watchedDuration", watchedDuration);
    },

    removeAddressBar() {
      setTimeout(() => {
        // This hides the address bar:
        window.scrollTo(0, 1);
      }, 0);
    },

    contentStreamResponse() {
      eventBus.$on("contentStreamUrl-response", (response) => {
        if (response.reason) {
          this.playbackErrorHandler(response);
        } else {
          this.streamUrl = response.streamfilename;
					contentItem.scrubbing = response.packagedfilelist && response.packagedfilelist.scrubbing ? response.packagedfilelist.scrubbing : '';

          this.constructPlayer();
        }
      });
    },

    assertPlayerHeight() {
      let playerContainer = document.getElementById("vlive-player-container");
      if (!playerContainer) return false;

      playerContainer.style.height = Math.round(playerContainer.offsetWidth / (16 / 9)) + "px";
    },

    playbackErrorHandler(error) {
      let errorTitle = "";
      let errorMessage = "";

      switch (error.errorcode) {
        case 8807:
          errorTitle = "Stream Url Error";
          errorMessage = error.reason;
          break;
        case 1002:
          break;
        default:
          break;
      }

      this.errorPopupData = {
        title: errorTitle,
        message: errorMessage,
      };

      this.toggleMessagePopup(true);
    },

    toggleMessagePopup(state) {
      this.isMessagePopup = state;
    },

    initPlayback() {
      switch (this.content.videoType) {
        case "Trailer":
          if (screen.width > 767) {
            this.streamUrl = this.content.trailer[0].filelist[0].filename;
          } else {
            this.streamUrl = this.content.trailer[0].filelist[1].filename;
          }
          this.constructPlayer();
          break;
        case "Content":
          // this.getRelatedContents();
          this.getContentStreamUrl();
          break;
      }
    },

    getPoster(content) {
      return content.poster[0].filelist[0].filename;
    },

    getContentStreamUrl() {
      let temporaryVideoTag = document.createElement("video");
      let platformOs = navigator.platform;

      let payload = {
        contentid: this.content.objectid,
        params: {
          availabilityid: this.content.playback_details.availabilityid,
          packageid: this.content.playback_details.packageid,
        },
      };

      if (temporaryVideoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          payload.params.mpegtspackage = "YES";
        }
      }

      eventBus.$emit("getContentStreamUrl", payload);
    },

    getRelatedContents() {
      // Playlist array.
      this.playlistArray = [];
      // Payload object
      let payload = { contentid: this.content.objectid };
      // Related contents api call.
      this.relatedContents(payload)
        .then((response) => {
          if (!response.data.reason) {
            let relatedContents = response.data.data;

            relatedContents.forEach((element) => {
              this.filteredAvailabilities = [];
              this.pricemodel = [];
              let content_details = null;
              // Fetch content details.
              content_details = this.fetchContentDetails(element.contentdetails);
              // Fetch availability id.
              this.fetchAvailabilityId(content_details);

              // Check if content availability is subscribed or not.
              this.filteredAvailabilities.forEach((filteredElement) => {
                // Check subscriptions and filter the contents.

                if (this.subscriberid && this.subscriptions.length > 0) {
                  this.subscriptions.forEach((subscription) => {
                    if (subscription.availabilityset.includes(filteredElement.availabilityid)) {
                      element.playback_details = {
                        packageid: content_details.packageid,
                        availabilityid: filteredElement.availabilityid,
                        drmscheme: content_details.drmscheme[0],
                      };
                    }
                  });
                }
              });
            });

            this.playlistArray = [this.content, ...relatedContents];
            console.log("playlist array", this.playlistArray);

            // Construct carousel.
            this.constructPlayer();
          }
        })
        .catch((error) => console.log(error));
    },

    constructPlayer() {
      let content = this.content;
      let playlist = [];

      let playlistItem = new vLivePlaylistItem({
        contentId: content.objectid,
        contenttype: content.category,
        source: [this.streamUrl],
        licenseServer: this.appConfig.providerDetails.drmLicenseUrl,
        thumbnail: this.getPoster(content),
        packageid: content.playback_details ? content.playback_details.packageid : "",
        providerid: this.appConfig.providerDetails.providerId,
        drmscheme: content.playback_details ? content.playback_details.drmscheme : "",
        availabilityid: content.playback_details ? content.playback_details.availabilityid : "",
        providerSession: localStorage.getItem("sessionToken"),
        isDrmContent: content.isDrmContent,
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
        position: content.watchedTime ? content.watchedTime : 0,
				scrubbing: content.scrubbing ?content.scrubbing[0] : '',
      });
      // Assigning content to playlist.
      playlist = [...playlist, playlistItem];

      console.log("Final playlist in constructPlayer-------------", playlist);

      // Rendering player on the dom.
      setTimeout(() => {
        this.renderPlayer(playlist);
      }, 100);
    },

    renderPlayer(playlist) {
      //config object for player from ouside.
      let config = {
        platform: "vliveweb",
        skipTime: 10,
        progressColor: "#FF9F0A",
        tokenApiURL: this.appConfig.providerDetails.drmTokenUrl,
        orientation: this.localDisplayLang === "ara" ? "rtl" : "ltr",
      };

      let playerSettings = {
        container: "vlive-player",
        showSettings: true,
        showCast: false,
        showPlaylist: true,
        hasWatermark: false,
        captionLang: "ar",
        displayLang: this.$i18n.locale,
        playerConfig: config,
      };

      if (this.$i18n.locale == "ara") {
        playerSettings.localeStrings = {
          qualityTitle: "الجودة",
          audioTitle: "الصوت",
          subtitleTitle: "ترجمة",
          offText: "إيقاف",
          autoText: "تلقاءي",
          ar: "العربية",
          tr: "التركية",
          ru: "الروسية",
          hi: "الهندية",
          en: "الإنجليزية",
          ko: "الكورية",
          zh: "صينى",
          uz: "الأوزبكية",
          kk: "الكازاخستانية"
        };
      }

      console.log("App player component creating vlive player");
      this.player = new vLivePlayer(playerSettings, playlist);
      // Now register the player events
      this.registerPlayerEvents();
    },

    registerPlayerEvents() {
      // Setting up the events
      this.player.registerEvent("contentloaded", this.videoContentLoadedCB);
      this.player.registerEvent("unloadstart", this.videoUnloadStartCB);
      this.player.registerEvent("contentplay", this.videoPlayContentCB);
      this.player.registerEvent("pausecontent", this.videoPauseContentCB);
      this.player.registerEvent("itemClickedPlayList-register", this.videoPlayListItemClicked);
      this.player.registerEvent("itemClickedPlayList", this.videoPlayListItemClicked);

      this.player.registerEvent("error", this.playerErrorCB);
    },

    videoContentLoadedCB(data) {},
    videoUnloadStartCB() {},

    videoPlayContentCB() {
      console.log("content is playing");
    },

    videoPauseContentCB() {
      console.log("content is paused");

      //watch event analytics.
      this.watchEvent();
      //watch branch analytics
      this.watchBranchInitiatedEvent();
    },

    getMenuCellData() {
      try {
        const menuCell = document.getElementsByClassName("vlive-menu-cell-group");

        if (menuCell && menuCell.length) {
          this.contentSettingInfo.quality = menuCell[0].children[1].children[1].innerText;

          for (let singleSub in menuCell[2].children) {
            if (
              menuCell[2].children[singleSub] &&
              menuCell[2].children[singleSub].children &&
              menuCell[2].children[singleSub].children[0].style.display !== "none"
            ) {
              this.contentSettingInfo.subtitle = menuCell[2].children[singleSub].children[1].innerText;
            }
          }

          for (let singleAudio in menuCell[1].children) {
            if (
              menuCell[1].children[singleAudio] &&
              menuCell[1].children[singleAudio].children &&
              menuCell[1].children[singleAudio].children[0].style.display !== "none"
            ) {
              this.contentSettingInfo.audio = menuCell[1].children[singleAudio].children[1].innerText;
            }
          }
        }
      } catch {
        console.error("element missed");
      }
    },

    videoContentProgressCB(progress) {
      this.progress = progress;

      if (this.counterTimer <= 1) {
        this.getMenuCellData();
      }

      this.throttledStreamAPIFunc();

      this.counterTimer += 1;
      this.contentSettingInfo.watchedTime = Math.floor(this.counterTimer / 4);

      this.timer += 1;
    },

    setContentStreamAPIFunc() {
      if (this.appConfig.featureEnabled.isConcurrencyEnabled) {
        console.log("THE INITIAL STREAM PARAMETER --- > ", this.startPositionForStreamApi);

        if (this.progress.position >= this.startPositionForStreamApi + 180) {
          console.log("INSIDE FUNC --- ", this.progress.position, this.startPositionForStreamApi);

          const payload = {
            contentId: this.progress.contentId,
            params: {
              devicetype: "PC",
            },
          };

          this.setCurrentStreamApi(payload).then((setStreamResponse) => {
            console.log("THIS IS THE SET STREAM API REPEAT RESPONSE -- > ", setStreamResponse);

            if (setStreamResponse.data.success) {
              this.startPositionForStreamApi = this.progress ? this.progress.position : 0;
            } else if (setStreamResponse.data.errorcode && setStreamResponse.data.errorcode === 9902) {
              this.hidePlayer();

              eventBus.$emit("showAuthorizeErrorMessage", {
                state: true,
                messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
              });
            } else if (setStreamResponse.data.errorcode && setStreamResponse.data.errorcode === 6066) {
              // 6066
              setTimeout(() => {
                this.setCurrentStreamApi(payload).then((setStreamResponseRecal) => {
                  if (setStreamResponseRecal.data.success) {
                    this.startPositionForStreamApi = this.progress.position;
                  } else if (setStreamResponseRecal.data.errorcode && setStreamResponseRecal.data.errorcode === 9902) {
                    this.hidePlayer();

                    eventBus.$emit("showAuthorizeErrorMessage", {
                      state: true,
                      messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
                    });
                  }
                });
              }, 5000);
            }
          });
        }
      }
    },

    unloadPlayer() {
      if (this.player) {
        this.player.unloadPlayer();
        this.player = undefined;
      }
    },

    getContentProgress() {
      let content = this.content;
      let path = `/subscriber/${this.subscriberid}/${this.profileid}/content/${content.objectid}`;

      firebase
        .database()
        .ref(path)
        .on("value", (snapshot) => {
          let response = snapshot.val();
          if (content && response) {
            if (response.status === "INPROGRESS") {
              let watched = response.watchedduration;
              this.content.position = watched;
            }
          }
          this.constructPlayer();
        });
    },

    createAnalyticsObj() {
      let content = this.content;

      console.log("THE WATCHED EVENT CONTENT --> ", content);

      const browser = this.getPlatformType();

      const country = this.getCountry;

      const playback_source = localStorage.getItem("playback_source");

      const watchTime = this.contentSettingInfo.watchedTime;

      let subtitle;

      let audio;

      subtitle = getSubtitleLanguageFromPlayer();
      audio = getAudioLanguageFromPlayer();

      if(this.subscriptionList) {
        this.contentpricemodel = "Paid";
      } else {
        this.contentpricemodel = "Free"
      } 

      console.log("THE CONTENT SETTINGS INFO ---> ",this.contentSettingInfo);

      if (this.localDisplayLang === "ara") {
        audio = this.getLanguageForSubtitleAndAudio(this.contentSettingInfo.audio);
        subtitle = this.getLanguageForSubtitleAndAudio(this.contentSettingInfo.subtitle);
      } else {
        audio = this.contentSettingInfo.audio;
        subtitle = this.contentSettingInfo.subtitle;
      }

      let content_lang = "";

      if (content.contentlanguage && content.contentlanguage.length) {
        console.log("THE LANGUAGES LIST ---> ", this.getAllLanguageFullForm);
        this.getAllLanguageFullForm.forEach(lang => {
          if(lang["alpha3-b"] === content.contentlanguage[0].toLowerCase()) {
            content_lang = lang.English;
          }
        })
      }
      const playerPositionPerecentage = this.progress ? ( this.progress.position / content.duration) * 100 : 0;

      let data = {
        content_id: content.objectid,
        content_title: content.defaulttitle ? content.defaulttitle : content.title,
        content_type: content.objecttype,
        content_genre: content.defaultgenre ? content.defaultgenre : content.genre,
        content_language: content_lang,
        watch_time: watchTime,
        content_duration: content.duration,
        content_price: this.contentpricemodel,
        episode_number: content.episodenum ? content.episodenum : "",
        playback_type: "Streaming",
        season_number: content.seasonnum ? content.seasonnum : "",
        streamed_upto_25perc: playerPositionPerecentage <= 25 ? "TRUE" : "FALSE",
        streamed_upto_50perc: playerPositionPerecentage > 25 && playerPositionPerecentage <= 50 ? "TRUE" : "FALSE",
        streamed_upto_75perc: playerPositionPerecentage > 50 ? "TRUE" : "FALSE",
        network_type: "WIFI",
        show_name: content.seriesname ? content.seriesname : "",
        finished_watching: playerPositionPerecentage >= 90 ? "TRUE" : "FALSE",
        start_position: this.startPositionForStreamApi ? this.startPositionForStreamApi : 0,
        source: playback_source,
        country: country.CountryName,
        day_of_week: this.getCurrentDayOfTheWeek(),
        time_of_day: this.getCurrentTimeFrame(),
        audio_language: audio,
        subtitle_language: subtitle,
        display_language: this.getCurrentDisplayLanguage(),
        platform: this.getPlatform(),
        platform_type: browser.browser,
      };

      console.log("THE ANALYTICS LOG --- > ", data);
      return data;
    },

    watchEvent() {
      // This Func is called when the Content Playback ends and next episode starts playing
      const param = this.createAnalyticsObj();

      const unSetPayload = {
        contentId: this.content.objectid,
      };

      if (this.appConfig.featureEnabled.isConcurrencyEnabled) {
        this.unsetCurrentStreamApi(unSetPayload).then((unSetStreamResp) => {
          console.log("THE UNSET PLAYER API IS CALLED before next playback starts--> ", unSetStreamResp);
        });
      }

      setTimeout(() => {
        this.updateDataOnFirebase();
      }, 1000);

      // Google Analytics Event
      this.watchedEvent(param);
    },

    watchBranchInitiatedEvent() {
      console.log("watchBranchInitiatedEvent ----------");
      this.branchInitiateStreamEvent(this.createAnalyticsObj());
    },

    watchBranchCompleteEvent() {
      console.log("watchBranchCompleteEvent--------------");
      this.branchCompleteStreamEvent(this.createAnalyticsObj());
    },

    hidePlayer() {
      this.playerInstance.shrinkPlayer();

      eventBus.$emit("player-visibility", false);

      let payload = { content: null, seriesContent: null };
      eventBus.$emit("load-player-content", payload);

      this.playerInstance.unloadPlayer(true);
    },
  },

  components: {
    message: () => import(/* webpackChunkName: "message" */ "@/components/Popups/message.vue"),
  },

  beforeDestroy() {
    document.body.style.overflow = "hidden";
  },

  mixins: [FirebaseActions, googleAnalytics, cleverTap, common, branchIO, Utility],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.player-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  z-index: 8888;
  .player-info {
    display: flex;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    .player-close-icon {
      cursor: pointer;
    }
    .rtl_player_close_icon {
      width: 25px;
      margin: 10px 15px;
      cursor: pointer;
    }
    .content-title {
      color: #ffffff;
      font-family: $font-regular;
      font-size: 35px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 50px;
    }
  }
  .rtl_align {
    right: 0;
    left: auto;
    width: 50%;
  }
}

@media only screen and (max-width: 576px) {
  .player-popup {
    .player-info {
      .player-close-icon {
        width: 25px;
        margin: 10px;
        cursor: pointer;
      }
      .rtl_player_close_icon {
        width: 15px;
        margin: 10px 15px;
        cursor: pointer;
      }
      .content-title {
        font-size: 14px;
        margin: 10px 0;
        line-height: 25px;
      }
    }
  }
}
</style>
